(function ($) {

  $(window).load(function () { // makes sure the whole site is loaded
    $('#preloader-status').fadeOut(); // will first fade out the loading animation
    $('#preloader').delay(200).fadeOut('slow'); // will fade out the white DIV that covers the website.
    $('body').delay(200).css({'overflow': 'visible'});
  });

  $(document).ready(function(){
    var logosDOMCopy= $(".logos").html();
    var brochureDOMCopy= $(".section-brochure").html();

    $.localScroll({
      offset: {
        top: -40
      },
    });

    resmenuInit();
    menuHelper();

    $('.box').matchHeight({});

    $(".section-gallery").owlCarousel({
      nav: true,
      loop:true,
      navRewind:false,
      items: 1
    });

    brochureCarouselSet();
    logosCarouselSet();

    $(window).off('resize.carousels').on('resize.carousels', function () {
      brochureCarouselSet();
      logosCarouselSet();
    });

    function brochureCarouselSet() {
      var windowWidth = $(window).width();
      var currentElementsSet = ''
      var newElementsSet = ''

      if ($(".section-brochure .owl-stage-outer").length) {
        if ($(".section-brochure .big").length && $(".section-brochure .medium").length) {
          currentElementsSet = 'desktop'
        }
        else if ($(".section-brochure .medium").length) {
          currentElementsSet = 'tablet'
        }
        else {
          currentElementsSet = 'mobile'
        }
      }

      if (windowWidth > 1350) {
        newElementsSet = 'desktop'
      } else if (windowWidth > 900) {
        newElementsSet = 'tablet'
      } else if (windowWidth > 0) {
        newElementsSet = 'mobile'
      }

      if (currentElementsSet !== newElementsSet) {
        $(".section-brochure").owlCarousel('destroy');
        $(".section-brochure").html(brochureDOMCopy);
        if (newElementsSet === 'tablet') {
          $(".section-brochure .big").remove()
        }
        if (newElementsSet === 'mobile') {
          $(".section-brochure .big, .section-brochure .medium").remove()
        }
        $(".section-brochure").owlCarousel({
          nav: true,
          loop:false,
          navRewind:false,
          responsive: {
            0: {
              items: 1,
              slideBy: 1
            },
            900: {
              items: 2,
              slideBy: 2
            },
            1350: {
              items: 3,
              slideBy: 3
            }
          }
        });
      }
    }

    function logosCarouselSet() {
      var windowWidth = $(window).width();
      var inGroupElementsCount = $(".logos .group > .logo").length
      var newInGroupElementsCount = 0

      if (windowWidth > 1100) {
        newInGroupElementsCount = 12
      } else if (windowWidth > 920) {
        newInGroupElementsCount = 12
      } else if (windowWidth > 698) {
        newInGroupElementsCount = 9
      } else if (windowWidth > 478) {
        newInGroupElementsCount = 6
      } else if (windowWidth > 100) {
        newInGroupElementsCount = 3
      }

      if (inGroupElementsCount !== newInGroupElementsCount) {
        $(".logos").owlCarousel('destroy');
        $(".logos").html(logosDOMCopy);
        $(".logos .logo").chunk(newInGroupElementsCount).wrap('<div class="group"></div>');
        $(".logos").owlCarousel({
          loop:false,
          navRewind:false,
          items: 1,
          nestedItemSelector: 'group',
        });
      }
    }

  });

  function resmenuInit(){
    $('#menu-switch').sidr({
      source: '.menu-bar',
      displace: false,
      side: 'right'
    });
    $('body').click(function () {
      $.sidr('close', 'sidr');
    });
    $(window).off('resize.sidr').on('resize.sider', function () {
      $.sidr('close', 'sidr');
    });
  }

  function menuHelper(){
    $(window).off('load.menu').on('load.menu', function() {
      contentMargin();
      stickyNav();
    });

    $(window).off('scroll.menu').on('scroll.menu', function(){
      contentMargin()
      stickyNav()
      setTimeout(function() {
        contentMargin()
        stickyNav()
      }, 300)
    })

    $(window).off('resize.menu').on('resize.menu', function(){
      contentMargin()
      setTimeout(function() {
        contentMargin()
      }, 300)
    })

    function contentMargin () {
      var height = $('#navigation').outerHeight();
      $('main').css({
        marginTop: height + 'px'
      })
      $.localScroll({
        offset: -height
      });
    }
    function stickyNav(){
      var nav = $('#navigation');
      if ($(window).scrollTop() <= 0) {
        $('body').removeClass('sticky');
      }
      if ($(window).scrollTop() > 0) {
        $('body').addClass('sticky');
      }
    }
  }

})(jQuery);


$.fn.chunk = function(size) {
  var arr = [];
  for (var i = 0; i < this.length; i += size) {
    arr.push(this.slice(i, i + size));
  }
  return this.pushStack(arr, "chunk", size);
}
